<template>
  <!-- 开放合作商的员工分成比例设置 -->
  <div>
    <el-dialog title="所属商务" :visible.sync="show" width="70%">
      <el-button type="primary" @click="open">新增商务员工</el-button>
      <auto-table :DataList="list" :option="Option" :showPage="false">
        <template #member_type="{ row }">
          <div v-if="row.map.division">{{ typeMap[row.map.division.member_type] }}</div>
        </template>
        <template #course_type="{ row }">
          <div v-if="row.map.division">{{ typeMap[row.map.division.course_type] }}</div>
        </template>
        <template #handle="{ row }">
          <el-button size="small" type="primary" @click="openUpdate(row)"
            >编辑</el-button
          >
          <el-button size="small" type="danger" @click="deleteItem(row)"
            >删除</el-button
          >
        </template>
      </auto-table>
      <el-dialog
        title="分成设置"
        :visible.sync="dialogVisible"
        width="35%"
        append-to-body
      >
        <el-form :model="form" label-width="120px">
          <el-form-item label="开发员工">
            <admin-select
              v-if="mode == 'add'"
              @change="changeAdmin($event)"
              :condition="{ is_employees: 1 }"
            ></admin-select>
            <div v-if="mode == 'edit'">
              {{ bonus.name }}
            </div>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员卡分成方式">
                <el-select v-model="form.member_type" placeholder="请选择">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>

            <el-col :span="12">
              <el-form-item label="会员卡分成">
                <el-input
                  type="number"
                  v-model="form.member"
                  :placeholder="placeholder[form.member_type]"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程分成方式">
                <el-select v-model="form.course_type" placeholder="请选择">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>

            <el-col :span="12">
              <el-form-item label="课程分成">
                <el-input
                  type="number"
                  v-model="form.course"
                  :placeholder="placeholder[form.course_type]"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button v-if="form.id" type="primary" @click="updateForm"
            >更 新</el-button
          >
          <el-button v-else type="primary" @click="addForm">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { ObjectChangedValue } from "@/js/utils.js";
import adminSelect from "../../../components/select/adminSelect.vue";

export default {
  components: {
    adminSelect,
  },
  data() {
    return {
      dialogVisible: false,
      show: false,
      mode: "add",
      placeholder: {
        1: "分成比例，如0.2",
        2: "填写金额，如100",
      },
      typeMap: {
        1: "比例结算",
        2: "固定金额",
      },
      typeOptions: [
        {
          label: "比例结算",
          value: 1,
        },
        {
          label: "固定金额",
          value: 2,
        },
      ],
      bonus: {},
      form: {},
      temp: {},
      cooperator_id: "",
      select_admin: {},
      list: [],
      Option: [
        { name: "员工", value: "name" },
        { name: "会员卡分成类型", value: "member_type", type: "custom" },
        { name: "会员卡分成", value: "map.division.member" },
        { name: "课程分成类型", value: "course_type", type: "custom" },
        { name: "课程分成", value: "map.division.course" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "200px" },
      ],
    };
  },

  mounted() {},

  methods: {
    //选择员工
    changeAdmin(admin) {
      this.select_admin.admin_id = admin.id;
      this.select_admin.name = admin.name;
    },
    getList() {
      this.$axios({
        url: "/user/adminBonus/queryByCoop",
        method: "get",
        params: {
          id: this.cooperator_id,
        },
      }).then((res) => {
        this.list = res.data.data;
      });
    },
    //新增
    addForm() {
      if (this.checkValue()) {
        if (!this.select_admin.admin_id) {
          this.$message("请选择员工");
          return;
        }
        for (let i = 0; i < this.list.length; i++) {
          if (this.select_admin.admin_id == this.list[i].admin_id) {
            this.$message("该员工已配置分成，请勿重复添加");
            return;
          }
        }
        this.form.cooperator_id = this.cooperator_id;
        this.form.admin_id = this.select_admin.admin_id;
        this.form.admin_name = this.select_admin.name;
        this.$axios({
          url: "/user/adminBonus/insertByCoop",
          method: "post",
          data: this.form,
        }).then((res) => {
          this.$notify({
            message: "分成设置成功",
            duration: 2000,
            type: "success",
          });
          this.getList();
          this.dialogVisible = false;
        });
      }
    },
    //更新
    updateForm() {
      if (this.checkValue()) {
        const data = ObjectChangedValue(this.temp, this.form);
        if (Object.keys(data).length == 0) {
          this.$message("没有修改数据，无需更新");
          return;
        }
        this.$axios({
          url: "/user/divisionProportion/update",
          method: "post",
          data: {
            id: this.form.id,
            ...data,
          },
        }).then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
        });
      }
    },
    deleteItem(row) {
      this.$confirm(`是否删除${row.name}的收益分成`).then(() => {
        this.$axios({
          url: "/user/adminBonus/deleteByCoop",
          params: { id: row.id },
        }).then((res) => {
          this.$notify({
            message: "删除成功",
            duration: 2000,
            type: "success",
          });
          this.getList();
        });
      });
    },
    openUpdate(row) {
      this.mode = "edit";
      this.bonus = row;
      let result = row.map.division || { member_type: 1, course_type: 1 };
      this.temp = Object.assign({}, result);
      this.form = result;
      this.dialogVisible = true;
    },
    open(mode) {
      this.mode = "add";
      this.form = { member_type: 1, course_type: 1 };
      this.temp = {};
      this.select_admin = {};
      this.dialogVisible = true;
    },
    visiable(id) {
      this.cooperator_id = id;
      this.getList();
      this.show = true;
    },
    checkValue() {
      if (this.form.member_type == 1 && this.form.member > 1) {
        this.$message("会员卡分成比例不能大于1");
        return false;
      }
      if (this.form.course_type == 1 && this.form.course > 1) {
        this.$message("课程分成比例不能大于1");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
</style>