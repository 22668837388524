<template>
  <!-- 合作商分成比例 新增，修改 -->
  <div>
    <el-dialog title="分成比例" :visible.sync="dialogVisible" width="35%">
      <p class="tip">
        设置合作商或物业的分成比例，比例结算是分成订单实收金额的比例
      </p>
      <p class="tip" v-if="cooperator.type != 'commerce'">合作商分成比例和下属物业的分成比例相互独立，请分别设置</p>
      <p class="tip" v-else>设置商会的分成比例，然后设置员工的比例（从商会收益中分），会把商会收益按比例分给员工</p>
      <p class="tip">比例结算方式最大为1，如5%填0.05</p>
      <el-form :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="会员卡分成方式">
              <el-select v-model="form.member_type" placeholder="请选择">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>

          <el-col :span="12">
            <el-form-item label="会员卡分成">
              <el-input
                type="number"
                v-model="form.member"
                :placeholder="placeholder[form.member_type]"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="课程分成方式">
              <el-select v-model="form.course_type" placeholder="请选择">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>

          <el-col :span="12">
            <el-form-item label="课程分成">
              <el-input
                type="number"
                v-model="form.course"
                :placeholder="placeholder[form.course_type]"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="消费金分成方式">
              <el-select v-model="form.consume_type" placeholder="请选择">
                <el-option label="比例结算" :value="1"> </el-option>
              </el-select> </el-form-item
          ></el-col>

          <el-col :span="12">
            <el-form-item label="消费金分成">
              <el-input
                type="number"
                v-model="form.consume"
                :placeholder="placeholder[form.consume_type]"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <div v-if="cooperator.type != 'commerce'">
          <el-popover
            placement="top-start"
            title="说明"
            width="400"
            trigger="hover"
            ><p class="tip">
              员工激活用户后，用户消费后，从物业分成中拿出一定比例分给激活员工
            </p>
            <p class="tip">
              如物业会员卡收益为100，员工会员激活分成0.1，那物业要将10元分成给员工。
            </p>
            <el-button type="text" slot="reference"
              >员工激活用户的分成比例，如10%填0.1</el-button
            >
          </el-popover>

          <el-form-item label="会员卡分成-激活">
            <div class="flex">
              <el-input
                v-model="form.member_active"
                placeholder="如0.1"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="消费金分成-激活">
            <div class="flex">
              <el-input
                v-model="form.consume_active"
                placeholder="如0.1"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="消费金分成-激活">
            <div class="flex">
              <el-input
                v-model="form.course_active"
                placeholder="如0.1"
              ></el-input>
            </div>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm"
          >更 新</el-button
        >
        <el-button v-else type="primary" @click="addForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  data() {
    return {
      dialogVisible: false,
      placeholder: {
        1: "分成比例，如0.2",
        2: "填写金额，如100",
      },
      typeOptions: [
        {
          label: "比例结算",
          value: 1,
        },
        {
          label: "固定金额",
          value: 2,
        },
      ],
      form: {},
      temp: {},
      cooperator_id: "",
      cooperator: {},
    };
  },

  mounted() {},

  methods: {
    getList() {
      this.$axios({
        url: "/user/divisionProportion/queryListPage",
        method: "get",
        params: {
          cooperator_id: this.cooperator_id,
          admin_id: 0,
          status: 1,
        },
      }).then((res) => {
        let result = res.data.data[0] || {
          member_type: 1,
          course_type: 1,
          consume_type: 1,
        };
        this.temp = Object.assign({}, result);
        this.form = result;
      });
    },
    //新增
    addForm() {
      if (this.checkValue()) {
        this.form.cooperator_id = this.cooperator_id;
        this.$axios({
          url: "/user/divisionProportion/insert",
          method: "post",
          data: this.form,
        }).then((res) => {
          this.$notify({
            message: "分成设置成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
        });
      }
    },
    //更新
    updateForm() {
      if (this.checkValue()) {
        const data = ObjectChangedValue(this.temp, this.form);
        if (Object.keys(data).length == 0) {
          this.$message("没有修改数据，无需更新");
          return;
        }
        this.$axios({
          url: "/user/divisionProportion/update",
          method: "post",
          data: {
            id: this.form.id,
            ...data,
          },
        }).then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
        });
      }
    },
    open(row) {
      this.cooperator_id = row.id;
      this.cooperator = row;
      this.form = {};
      this.temp = {};
      this.getList();
      this.dialogVisible = true;
    },
    checkValue() {
      if (this.form.member_type == 1 && this.form.member > 1) {
        this.$message("会员卡分成比例不能大于1");
        return false;
      }
      if (this.form.course_type == 1 && this.form.course > 1) {
        this.$message("课程分成比例不能大于1");
        return false;
      }
      if (this.form.consume_type == 1 && this.form.consume > 1) {
        this.$message("课程分成比例不能大于1");
        return false;
      }
      if (
        this.form.consume_active > 1 ||
        this.form.course_active > 1 ||
        this.form.member_active > 1
      ) {
        this.$message("激活分成比例不能大于1");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  margin-bottom: 10px;
}
</style>